import { Transaction } from '../reducers/transactionReducer'

export const MOCK_BLOCK_LIST_DATA = [
  {
    index: 786786,
    time: 1510884204,
    size: 686,
    tx: ['0x332090115531dac3bf046d9f68413c41f4e438d6e959738bb577ccd66c4c99d2'],
    blocktime: 21,
    hash: '0x8207fd1bec74fd2af9c52d523f6fda3525b0a2b7555298ab4bb20087415dcce1',
    txCount: 1,
  },
  {
    index: 786785,
    time: 1510884183,
    size: 686,
    tx: ['0xf580984be4c0926f9cc4486f5a0ac1d5a0652059cf6c35b7859bbc93da6e6bd7'],
    blocktime: 24,
    hash: '0xc09e2a161589c103f6e245744ed1264e5cd35ebdb79f879a7db3679d20aabe41',
    txCount: 1,
  },
  {
    index: 786784,
    time: 1510884159,
    size: 686,
    tx: ['0x5d24df9bd71e2b97f25d268b3d5d8f9ed87464868ff3db860a50782a259c45d8'],
    blocktime: 24,
    hash: '0x126f57f706fd07623aa2620e3f95cf15c0e4f42994443bccf7c5b631e93590ee',
    txCount: 1,
  },
  {
    index: 786783,
    time: 1510884135,
    size: 686,
    tx: ['0x079a3f5ac617f6100b8cdc6a8817f70ee049dfa6afd20e203c3698e12052f2e2'],
    blocktime: 27,
    hash: '0xc35390bd14c4800dad7c3acc29c00f210a12338431f784ac280f67222064d8f2',
    txCount: 1,
  },
  {
    index: 786782,
    time: 1510884108,
    size: 686,
    tx: ['0xe14820ca14a9347f9b26e71359dd5ac2ff49017fe4ba2b101967ed987b48e8c7'],
    blocktime: 26,
    hash: '0x36016ad7684ead70568de0dbef7d4ec9deb95a99a81fdb4f354c2d533babcf3d',
    txCount: 1,
  },
  {
    index: 786781,
    time: 1510884082,
    size: 888,
    tx: [
      '0x8a3b147f62f16489719d323c5f7687990466ac8f5bb8b8581043f97f56888524',
      '0xc90113a346c704d07811fa72a48d757d86019bd7e84d1d7e6c630e71b5f8e7f3',
    ],
    blocktime: 27,
    hash: '0x091f4b38a176fb05e7f6a7303a1be675e0ff7b4a77fd640f2dfd128d941f7a17',
    txCount: 2,
  },
  {
    index: 786780,
    time: 1510884055,
    size: 686,
    tx: ['0xd65073dc9bb64fedb4bb43d57fb83ea8ae354cac370f5da04eef3a877f930089'],
    blocktime: 20,
    hash: '0x221e3ef8afc7ded6fb2daa38ae0401cad47840a4a1697f954f4a43e4beb5eddd',
    txCount: 1,
  },
  {
    index: 786779,
    time: 1510884035,
    size: 686,
    tx: ['0x80de7957dd206d2038aec04a0d09db320ea118379be901171a2362284cfe8fb0'],
    blocktime: 23,
    hash: '0x53bd2d626973065615c43aed14ebabeb603a2562c6fb76e35fef0a1c92c4fedc',
    txCount: 1,
  },
  {
    index: 786778,
    time: 1510884012,
    size: 1118,
    tx: [
      '0xe8bc74f7a946c0bb88d04520fb01de974a8ae131242c00f2df560b90271f5d21',
      '0x0a954f1adc87c1dc79ef14b0b12a69d2dce1b18b2c67697bb764db3c563629f8',
    ],
    blocktime: 23,
    hash: '0x9731a6c365f2fdf9ca48eaaa2a8fbc10c47a3385bf3bc24fb7a2464736d844e0',
    txCount: 2,
  },
  {
    index: 786777,
    time: 1510883989,
    size: 686,
    tx: ['0x31bc89691305f1f0d3db6a45dd2540f08b81ee8ee84787a62ecca936722dc0e4'],
    blocktime: 20,
    hash: '0xbdf054a24d87eff1115af3c3f0ab68268882c2573b71916d0558c354541c5e5d',
    txCount: 1,
  },
  {
    index: 786776,
    time: 1510883969,
    size: 1084,
    tx: [
      '0xaf46537cb863befe87b27e91db999b006a115d87323b4bf5463968d3729d584a',
      '0x7a053c2c33b8718c570bb736e7835c8d421728c761072baa01013c8a2f33e2ad',
    ],
    blocktime: 29,
    hash: '0xf7eb2c3057c3c5715a28a41b6b5c8d7d587b3a5122fc05dd8eb29f12021eb39d',
    txCount: 2,
  },
  {
    index: 786775,
    time: 1510883940,
    size: 686,
    tx: ['0x380c72f8cb49efd7b69d156cdb942a620461be4b95980684183e84f223e5b762'],
    blocktime: 33,
    hash: '0x9be75481ff0f3d5f4e4238dc43082a02b35a4f59b0f15ae9bf096fd448068923',
    txCount: 1,
  },
  {
    index: 786774,
    time: 1510883907,
    size: 686,
    tx: ['0x139f7ea61cdc053b61292d80e20f2c8ad88a810e6c8638e8bcbd29f025f702f1'],
    blocktime: 27,
    hash: '0x150dea7e5b008ef539ee73e00d0aa0d30230341659407989fc8ad89f46144ea9',
    txCount: 1,
  },
  {
    index: 786773,
    time: 1510883880,
    size: 686,
    tx: ['0xee0490072b1cf85e8c56156a4917334ee3b64b339c11593a6e8baa50d29f6eba'],
    blocktime: 20,
    hash: '0x722f18d875df501c5de8c7b159375380e696cba225eef0ca159c41a78c255ac4',
    txCount: 1,
  },
  {
    index: 786773,
    time: 1510883880,
    size: 686,
    tx: ['0xee0490072b1cf85e8c56156a4917334ee3b64b339c11593a6e8baa50d29f6eba'],
    blocktime: 20,
    hash: '0x722f18d875df501c5de8c7b159375380e696cba225eef0ca159c41a78c255ac4',
    txCount: 1,
  },
]

export const MOCK_TX_LIST_DATA = Array.from(
  { length: 15 },
  (_, i) =>
    ({
      time: 666,
      size: 666,
      hash: '',
    } as Transaction),
)
export const MOCK_CONTRACT_LIST_DATA = [
  {
    block: 5785119,
    hash: '0x53b7577befb37d4d3b95a02f60f5da8933ab5f04',
    time: 1593515187,
    name: 'Novem Gold Token',
    idx: 198,
    author: 'Christoph Klocker',
    asset_name: 'Novem Gold Token',
    symbol: 'NNN',
    type: 'NEP5',
  },
  {
    block: 5526686,
    hash: '0xd28eae9122cab2de5e42e9898798672437a2996d',
    time: 1589183591,
    name: 'NeoLine Token',
    idx: 191,
    author: 'NeoLine',
    asset_name: 'NeoLine Token',
    symbol: 'LINE',
    type: 'NEP5',
  },
  {
    block: 5470712,
    hash: '0xd9d5e856363e8b9a2462823b32ba6621bff23160',
    time: 1588248918,
    name: 'YIN',
    idx: 189,
    author: 'YouFish',
    asset_name: 'YIN',
    symbol: 'YIN',
    type: 'NEP5',
  },
  {
    block: 5153346,
    hash: '0xda2829d1b6dd9d92a4781f69f50ccb307e0278ea',
    time: 1582914927,
    name: '0xRacers PartAuction',
    idx: 187,
    author: '0xGames',
    asset_name: '',
    symbol: '',
    type: '',
  },
  {
    block: 5153239,
    hash: '0xf4f6f13544e1c45faea4af06243374c81878ea00',
    time: 1582913153,
    name: '0xRacers Shop',
    idx: 185,
    author: '0xGames',
    asset_name: '',
    symbol: '',
    type: '',
  },
  {
    block: 4885827,
    hash: '0xcea5a3963a5813a26accc6bc67e9be9d14d395f0',
    time: 1578459378,
    name: 'Moonlight LX contract',
    idx: 179,
    author: 'Moonlight',
    asset_name: 'Moonlight Lux',
    symbol: 'LX',
    type: 'NEP5',
  },
  {
    block: 4765256,
    hash: '0x87c9709724c693de481dca75943bbcb853000f6f',
    time: 1576471770,
    name: 'NFTMarket',
    idx: 178,
    author: 'james',
    asset_name: '',
    symbol: '',
    type: '',
  },
  {
    block: 4589596,
    hash: '0x46515c0060171592fada19263a00c6ad94ec3e84',
    time: 1573573231,
    name: 'CZ',
    idx: 169,
    author: 'CZ',
    asset_name: '',
    symbol: '',
    type: '',
  },
  {
    block: 4556782,
    hash: '0x9bcbf32f725fdf9bdd8eefa669463bc8dd077fea',
    time: 1573033417,
    name: 'Horos',
    idx: 168,
    author: 'Time Innovation Pte. Ltd.',
    asset_name: 'Horos',
    symbol: 'HRS',
    type: 'NEP5',
  },
  {
    block: 4495245,
    hash: '0xb15dc218d035fa3e15062f166712e2290dca3d60',
    time: 1571989469,
    idx: 167,
    asset_name: 'UNICORN',
    symbol: 'UNI',
    type: 'NEP5',
  },
  {
    block: 4449628,
    hash: '0xdeab2e1780ad1f8c111a5e12b6488b5ddf6bc882',
    time: 1571209102,
    name: 'Oil',
    idx: 164,
    author: 'james',
    asset_name: 'Oil Company Stock',
    symbol: 'OIL',
    type: 'NEP5',
  },
  {
    block: 4336502,
    hash: '0x59d232f5d6dba0e6aca5c212209b2f6676797ca8',
    time: 1569311464,
    name: 'xpet',
    idx: 158,
    author: 'noone',
    asset_name: '',
    symbol: '',
    type: '',
  },
  {
    block: 4193302,
    hash: '0x89ac564adf253628be5061f45c14049a8ada4cf1',
    time: 1566913036,
    name: 'BlockchainCuties',
    idx: 155,
    author: 'kindex',
    asset_name: 'BlockchainCuties',
    symbol: 'CUTIE',
    type: 'NEP5',
  },
  {
    block: 4101960,
    hash: '0x75a3b9736da151b65ec44e4913ebb53d32cd9ca4',
    time: 1565245240,
    name: 'CAKE',
    idx: 150,
    author: 'CardMaker',
    asset_name: 'CardMaker Alchemists Knowledge Energy (CardMaker Token)',
    symbol: 'CAKE',
    type: 'NEP5',
  },
  {
    block: 4065474,
    hash: '0x316b64de7ac3de00e1d59f2c209a31c3bb131cbd',
    time: 1564427726,
    name: 'AK12',
    idx: 147,
    author: 'AK12 Team',
    asset_name: 'AK12',
    symbol: 'AK12',
    type: 'NEP5',
  },
]

export const MOCK_CONTRACTS_INVOCATIONS_DATA = [
  {
    name: 'HashPuppies NFT Test 2',
    hash: '0x5b61f4cf3d75e3d55e41737d9638e1277c6f643c',
    count: 48819,
    change: '+100.00',
  },
  {
    name: 'fish',
    hash: '0x94a24ee381bc386daa91984c7dd606f6fdd8f19e',
    count: 2476,
    change: '-34.46',
  },
  {
    name: 'oracle',
    hash: '0xfde69a7dd2a1c948977fb3ce512158987c0e2197',
    count: 850,
    change: '-1.16',
  },
  {
    name: 'T_COIN',
    hash: '0x8f2ef174a387fad2d9f81a0018f45676864e4a02',
    count: 31,
    change: '+100.00',
  },
  {
    name: 'Developer NFT Minter',
    hash: '0x5b9c51062ccd3c99346febb4fda31dbe506e92d9',
    count: 17,
    change: '+100.00',
  },
  {
    name: 'Dev Coin',
    hash: '0xb51adf56e8a7d2ea8ff80d0e5b18596b34f5dbac',
    count: 12,
    change: '-76.47',
  },
  {
    name: 'dice02281357',
    hash: '0x6dbd26d6faac13e5cb9caa067c65ce68cab33af2',
    count: 9,
    change: '+350.00',
  },
  {
    name: 'CGAS',
    hash: '0x74f2dc36a68fdc4682034178eb2220729231db76',
    count: 6,
    change: '+200.00',
  },
  {
    name: 'QLC',
    hash: '0xb9d7ea3062e6aeeb3e8ad9548220c4ba1361d263',
    count: 6,
    change: '0.00',
  },
  {
    name: 'wwx',
    hash: '0xb2d966f84eb8128f4b86c9e226c7085c2af47b5f',
    count: 5,
    change: '+100.00',
  },
  {
    name: 'sar4c',
    hash: '0x1775f1af5df77ff876b2d7a06e256f1388199dc7',
    count: 4,
    change: '+100.00',
  },
  {
    name: 'sneo',
    hash: '0x789afc2bba96905d628cb41598c8f8cfcf213b58',
    count: 4,
    change: '+100.00',
  },
  {
    name: 'sdusd',
    hash: '0x80fe8494d517a0c9caaabd5ddffd48593f67d70f',
    count: 3,
    change: '+100.00',
  },
  {
    name: 'LOCALTOKEN PYNEP5',
    hash: '0xd7678dd97c000be3f33e9362e673101bac4ca654',
    count: 2,
    change: '-66.67',
  },
  {
    name: '1',
    hash: '0x4b4deb4caad37fcfbadcfefc0bebfc869ff523ea',
    count: 1,
    change: '+100.00',
  },
  {
    name: '8 decimal token',
    hash: '0xdcb8834bb3f4fa4a03d1d6d4140bf8fa897bb1a4',
    count: 1,
    change: '+100.00',
  },
  {
    name: 'test1',
    hash: '0xde8f159ad0e441e9253f184ebe70eae34c3af673',
    count: 1,
    change: '+100.00',
  },
  {
    name: 'dgtcontract',
    hash: '0xae0c18c8c9548fa0221521305f8ba36b4e250f12',
    count: 1,
    change: '+100.00',
  },
  {
    name: 'dgtcontract',
    hash: '0x74916afa0f079ef977dbb524c37dfe00087364f3',
    count: 1,
    change: '0.00',
  },
]

export const MOCK_NODES = []
